<template>
    <div class="modal-photo">
        <img :src="options.image" @error="$event.target.src = $blankProfile" />
        <div class="button-wrapper items-center">
            <button
                v-for="button in options.buttons"
                :key="button.id"
                v-html="button.label"
                @click="onClickButton(button.id)"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalPhoto',
    props: {
        options: {
            type: Object,
            validator: obj => {
                const requiredProperties = ['image'].filter(
                    property => !Object.prototype.hasOwnProperty.call(obj, property),
                )

                return requiredProperties.length === 0
            },
        },
    },
    methods: {
        onClickButton(buttonId) {
            if (this.options.from === 'showTicketDiscountModal' && buttonId === 0) {
                this.$modal
                    .basic({
                        body:
                            '정말 쿠폰을 받지 않으시겠어요?<p class="c-pink-deep f-12">창을 닫으면 더 이상 쿠폰을 받을 수 없어요</p>',
                        buttons: [
                            {
                                label: '받지 않을래요',
                                class: 'btn-default',
                            },
                            {
                                label: '받을래요',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) this.$emit('close', buttonId)
                    })
            } else {
                this.$emit('close', buttonId)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.modal-photo {
    $side-gap: 24px;
    $button-wrapper-height: 54px;

    width: calc(100vw - 2 * #{$side-gap});
    height: calc(100vw - 2 * #{$side-gap} + #{$button-wrapper-height});
    padding: 0;
    border-radius: 16px;
    @include flex;

    img {
        width: 100%;
        height: calc(100vw - 2 * #{$side-gap});
    }

    .button-wrapper {
        height: $button-wrapper-height;
    }

    button {
        width: 50%;
        height: $button-wrapper-height;
        border: none;
        font-size: 16px;
        color: $grey-07;
        letter-spacing: -0.2px;
        line-height: normal;
        font-weight: normal;
    }
}
</style>
